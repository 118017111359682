'use client'

import { useState } from 'react'

function PlusIcon() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			fill='none'
			stroke='currentColor'
			strokeWidth='1.5'
			className='w-6 h-6'
			viewBox='0 0 24 24'
		>
			<path strokeLinecap='round' strokeLinejoin='round' d='M12 4.5v15m7.5-7.5h-15'></path>
		</svg>
	)
}

export default function FloatingBtn() {
	const [isOpen, setIsOpen] = useState(false)
	const toggleDropdown = () => setIsOpen(!isOpen)

	const menuItems = [
		{ id: 1, label: 'New Custom Chat', href: '/conversation/new/uncategorized' },
		{ id: 2, label: 'New Launch Asset', href: '/conversation/new' },
		{ id: 3, label: 'New Generator', href: '/generator' },
	]

	return (
		<div className='fixed bottom-8 right-8'>
			<button
				onClick={toggleDropdown}
				className='bg-blue-600 text-white p-2 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50'
			>
				<PlusIcon />
			</button>
			{isOpen && (
				<div className='mt-2 py-2 w-44 bg-white rounded-lg absolute bottom-8 right-4 border border-slate-100'>
					{menuItems.map((item) => (
						<a
							key={item.id}
							href={item.href}
							className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 border-b border-slate-100 last:border-0'
						>
							{item.label}
						</a>
					))}
				</div>
			)}
		</div>
	)
}
